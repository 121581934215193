import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.octo = 'https://octo.smart-whats.com'
window.api = 'http://127.0.0.1:3000'
window.api = 'https://server.smart-whats.com'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

if(window.location.href.includes("?jwt=")){
  localStorage.setItem("user", JSON.stringify({
    jwt: window.location.href.split("?jwt=")[1]
  }))
  if(window.location.href.includes("subscribe")){
    $("body").html(`
    <center>
    <br><br>
    <H1>الاشتراك مجاني</H1>
    <h4>هذه خدمة مجانية</h4>
    </center>
    `)
    throw "";
  }else{
    window.location = window.location.href.split("?jwt=")[0];
    throw "";
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
