import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import('@/views/subscribe/index.vue')
    },
    {
      path: '/replies',
      name: 'replies',
      component: () => import('@/views/messages/replies.vue')
    },
    {
      path: '/octo',
      name: 'octo',
      component: () => import('@/views/web/octo.vue')
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/devices/index.vue')
    },
    {
      path: '/contacts/add',
      name: 'contacts-add',
      component: () => import('@/views/contacts/add.vue')
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('@/views/contacts/index.vue')
    },
    {
      path: '/groups',
      name: 'groups',
      component: () => import('@/views/contacts/groups.vue')
    },
    {
      path: '/archive',
      name: 'archive',
      component: () => import('@/views/messages/archive.vue')
    },
    {
      path: '/api',
      name: 'api',
      component: () => import('@/views/web/api.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/web/contact.vue')
    },
    {
      path: '/repeated',
      name: 'repeated',
      component: () => import('@/views/contacts/repeated.vue')
    },
    {
      path: '/send/devices',
      name: 'send-devices',
      component: () => import('@/views/messages/send-devices.vue')
    },
    {
      path: '/send/excel',
      name: 'send-excel',
      component: () => import('@/views/messages/send-excel.vue')
    },
    {
      path: '/reseller/share',
      name: 'reseller/share',
      component: () => import('@/views/reseller/share.vue')
    },
    {
      path: '/reseller/report',
      name: 'reseller/report',
      component: () => import('@/views/reseller/report.vue')
    },
    {
      path: '/sms',
      name: 'sms',
      component: () => import('@/views/sms/index.vue')
    },
    {
      path: '/settings/profile',
      name: 'settings/profile',
      component: () => import('@/views/settings/profile.vue')
    },
    {
      path: '/settings/process-numbers',
      name: 'settings/process-numbers',
      component: () => import('@/views/settings/process-numbers.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/auth/logout.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/activate',
      name: 'activate',
      component: () => import('@/views/auth/activate.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('@/views/auth/forget.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/widget/:jwt/:device/:number',
      name: 'widget',
      component: () => import('@/views/widget/index.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/rg/:id',
      name: 'rg',
      component: () => import('@/views/web/rg.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
